<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container">
        <!-- Provides the application the proper gutter -->
        <div class="head-title light-bg d-flex justify-space-between mb-0 pa-9">
          <h2 class="headline font-weight-medium primary--text">
            {{ $t('createCampaign.createCamp') }}
          </h2>
        </div>

        <v-container fluid class="px-9">
          <!-- If using vue-router -->
          <template fluid>
            <v-card elevation="0" class="createCampaignBox">
              <!-- Tabs Open -->
              <!-- <v-divider></v-divider> -->
              <v-row>
                <v-col cols="12" sm="6">
                  <br />
                  <h2 class="text-h6 font-weight-medium mb-3">
                    {{ $t('createCampaign.h2') }}
                  </h2>
                  <p class="text-subtitle-2 mb-3">
                   {{ $t('createCampaign.para') }}
                  </p>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-form>
                        <h3 class="mt-5 secondary--text">
                         {{ $t('createCampaign.select') }}
                        </h3>

                        <v-radio-group v-model="radioGroup">
                          <v-row class="mt-0">
                            <v-col cols="12" sm="12">
                              <div class="upload-file">
                                <v-label block class="pb-4"></v-label>

                                <v-radio>
                                  <template v-slot:label>
                                    <div>
                                      <strong>{{ $t('createCampaign.searchResults') }} </strong>
                                      <div class="text-subtitle-2">
                                        {{ $t('createCampaign.srData') }}
                                      </div>
                                    </div>
                                  </template>
                                </v-radio>
                              </div>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12" sm="12">
                              <div class="upload-file">
                                <v-label block class="pb-4"></v-label>
                                <v-radio>
                                  <template v-slot:label>
                                    <div>
                                      <strong>{{ $t('createCampaign.searchTab') }} </strong>
                                      <div class="text-subtitle-2">
                                        {{ $t('createCampaign.stData') }}
                                      </div>
                                    </div>
                                  </template>
                                </v-radio>
                              </div>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <!-- Tabs Open -->
              <v-row class="mt-3 float-right">
                <v-col cols="12" sm="12">
                  <v-btn color="disable" outlined class="mr-3" @click="back()"
                    >{{ $t('createCampaign.cancel') }}</v-btn
                  >
                  <v-btn color="success" elevation="0" class="mr-3" @click="selectCampaign()"
                    >{{ $t('createCampaign.continue') }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import LoadingAlert from '../components/LoadingAlert';
import InfoAlert from '../components/InfoAlert';
import DecisionAlert from '../components/DecisionAlert';
import EditCard from '../components/chargebee/EditCard.vue';

export default Vue.extend({
  name: 'CampaginView',
  data() {
    return {
      radioGroup: 0,
    };
  },
  components: {
    LoadingAlert,
    InfoAlert,
    DecisionAlert,
    EditCard,
  },
  methods: {
    selectCampaign() {
      if (this.radioGroup == 0) {
        return this.$router.push('/create-campaign');
      } else if (this.radioGroup == 1) {
        return this.$router.push('/searchtab-campaign');
      }
    },

    back() {
      return this.$router.push('/ads-manager');
    },
  },
});
</script>
