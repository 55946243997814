


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { COUNTRIES } from '../utils/constants';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { CampaignsService } from '@services/campaigns.service';
import { CustomerTypes, Genders } from '../utils/enums';
import {
  IAudience,
  ICampaign,
  IAdGroup,
  ICampaignReq,
} from '@shared/interfaces';
import XLSX from 'xlsx';

import AddCreativeSet from '../components/campaign/AddCreativeSet.vue';

import { LS_DRAFT_KEY } from '@shared/config';
import {
  GET_CAMPAIGN_DRAFT,
  GET_CAMPAIGN_GROUPS,
  APPS_BY_ORG,
  CHECK_CAMPAIGN_NAME,
  COUNTRIES_FOR_APP,
} from '../utils/apollo-queries';
import { CAMPAIGN_DRAFT, CREATE_CAMPAIGN } from '../utils/apollo-mutations';
import { CampaignCreationSteps } from '../utils/enums';
import { IMutationOptions } from '../utils/interfaces';
import AddKeys from '../components/keywords/AddKeys.vue';
import AddnKeys from '../components/keywords/AddnKeys.vue';
import { forEach } from 'lodash';
import { updateCLF } from '../utils/util';
import { APP_METADATA } from 'src/utils/apptweakqueries';

export default Vue.extend({
  components: {
    AddCreativeSet,
    AddKeys,
    AddnKeys,
  },
  data() {
    return {
      areMultipleCountries: false,
      fullscreenLoader: null,
      savingCampaignDraft: false,
      savingCountriesRegions: false,
      addedCreativeSetDetails: null,
      targetingKeywordsFile: null,
      negativeKeywordsFile: null,
      expandAdScheduling: false,
      savingCampaign: false,
      addKeywordsData: {},
      addnKeywordsData: {},
      csCountries: [],
      menuDisplays: null,
      ex4: null,
      successDialog: false,
      phones: [
        {
          size: '6.5” Display',
          description: 'Display iPhone XS Max',
        },
        {
          size: '6.1” Display',
          description: 'Display iPhone XR',
        },
        {
          size: '5.8” Display',
          description: 'Display iPhone X, iPhone XS',
        },
      ],
      ipads: [
        {
          size: '12.9” Display',
          description: 'Display iPad Pro 12.9”',
        },
        {
          size: '9.7” Display',
          description:
            'Display iPad Pro 9.7”, iPad (2017), iPad Air 2, iPad Air, iPad (4th generation)',
        },
      ],
      orgId: '',
      currency: '',
      campaign: null,
      adGroup: null,
      loading: false,
      searchedApps: [],
      search: null,
      searchLoc: null,
      selectedApp: null,
      campaginGroups: [],
      drawer: true,
      mini: true,
      step: CampaignCreationSteps.APP_SELECTION,
      previousStep: null,

      switch1: true,
      switch2: false,

      devices: ['Foo', 'Bar', 'Fizz', 'Buzz'],

      dialog: false,

      e2: 'Texas',
      e3: null,
      e4: null,
      states: [
        { text: 'State 1' },
        { text: 'State 2' },
        { text: 'State 3' },
        { text: 'State 4' },
        { text: 'State 5' },
        { text: 'State 6' },
        { text: 'State 7' },
      ],
      nullDatetime: null,
      datePickerProps: {
        min: new Date().toISOString(),
      },
      datetime: new Date(),
      datetimeString: '2019-01-01 12:00',
      formattedDatetime: '09/01/2019 12:00',
      textFieldProps: {
        appendIcon: 'event',
      },
      dateProps: {
        headerColor: 'red',
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      endTimeCheck: false,

      appList: [],
      //start
      valid: true,

      //campaign
      campaignGroupName: '',
      appId: null,
      appDetailsForCreativeSet: null,

      //Countries Regions
      campaignName: null,
      selectedCountries: null,
      appCountries: [],
      Countries: null,
      budget: null,
      dailyCap: '',

      //adgroup
      adGroupName: '',
      selectDevices: '',
      Devices: ['IPAD', 'IPHONE'],
      startdate: null,
      enddate: '',
      menu1: false,
      startmodal: false,
      endmodal: false,
      menu2: false,
      defaultBidAmount: '',
      cpaGoal: '',
      daypart: null,
      checkbox: true,
      menu: null,
      date: null,

      //Keywords
      tKeys: [],
      nKeys: [],

      //search match
      autoOpt: false,

      //audience
      ageRange: false,
      ageRangeValue: [18, 35],
      locations: [],

      selectedGender: Genders.ALL,
      selectedCustomerType: CustomerTypes.ALL,
      selectedLocation: '',

      //creative sets
      noCreativeSet: false,
      creativeappassets: null,
      creativeSetName: [],
      csName: '',
      tab: null,
      allImgs: {},
      selectedImgs: [],
      defaultImages: [],
      defaultGenIds: [],

      //Validation
      valid1: false,
      campaignGroupRules: [(v) => !!v || 'Please select campaign group!'],
      appRules: [(v) => !!v || 'Please select an app!'],

      valid2: false,
      countryRules: [(v) => !!(v && v.length) || 'Please select country!'],
      campaignNameRules: [(v) => !!v || 'Campaign name required!'],
      budgetRules: [
        (v) => !!v || 'Budget amount is required!',
        (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid budget!',
      ],

      valid3: false,
      adgroupNameRules: [(v) => !!v || 'Adgroup name required!'],
      cptBidRules: [
        (v) => !!v || 'Default Max CPT Bid is required!',
        (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid CPT Bid!',
      ],
      dailyCapRules: [
        (v) =>
          !!/^[0.1-9|(^$)]*$/gi.test(v) || 'Please enter a valid daily cap!',
      ],
      cpaGoalRules: [
        (v) =>
          !!/^[0.1-9|(^$)]*$/gi.test(v) || 'Please enter a valid CPA goal!',
      ],
      deviceRules: [(v) => !!(v && v.length) || 'Please select devices!'],
    };
  },

  computed: {
    c: function (): string {
      return '';
    },
    draftId: {
      cache: false,
      get(): string {
        return localStorage.getItem(LS_DRAFT_KEY) as string;
      },
      set(value: string): void {
        localStorage.setItem(LS_DRAFT_KEY, value);
      },
    },

    nKeysheaders: {
      cache: false,
      get() {
        return [
          {
            text: '',
            align: 'start',
            value: 'delete',
          },
          {
            text: this.$t('createCampaign.key'),
            sortable: true,
            value: 'text',
          },
          { text: this.$t('createCampaign.matchType'), value: 'matchType' },
        ];
      },
    },

    tKeysheaders: {
      cache: false,
      get() {
        return [
          {
            text: '',
            align: 'start',
            value: 'delete',
          },
          {
            text: this.$t('createCampaign.key'),
            sortable: true,
            value: 'text',
          },
          { text: this.$t('createCampaign.matchType'), value: 'matchType' },
          { text: this.$t('createCampaign.amount'), value: 'bidAmount.amount' },
          {
            text: this.$t('createCampaign.currency'),
            value: 'bidAmount.currency',
          },
        ];
      },
    },

    //audience
    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.all'), value: Genders.ALL },
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },
  },
  apollo: {
    apps: {
      query: APPS_BY_ORG,
      variables() {
        return {
          orgId: this.orgId,
        };
      },
      skip() {
        return !this.orgId;
      },
      update(data) {
        this.appListing(data.apps);
      },
    },
    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.selectedApp.adamId,
        };
      },
      skip() {
        return !this.selectedApp;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },
    campaignDraft: {
      query: GET_CAMPAIGN_DRAFT,
      variables() {
        return {
          draftId: this.draftId,
        };
      },
      skip: true,
      fetchPolicy: 'no-cache',
      async update(data) {
        await this.patchCampaignFormWithDraft(
          data.campaignDraft as ICampaignReq
        );
        this.fullscreenLoader.hide();
      },
    },
    getCampaignGroups: {
      query: GET_CAMPAIGN_GROUPS,
      skip(): boolean {
        return false;
      },
    },
  },
  methods: {
    appListing(data) {
      for (const app of data) {
        this.$apollo
          .query({
            query: APP_METADATA,
            variables: {
              appId: app.adamId,
              location: 'us',
              language: 'en',
              device: 'iphone',
              startDate: new Date().toISOString().substr(0, 10),
              endDate: new Date().toISOString().substr(0, 10),
            },
          })
          .then((data) => {
            this.appList.push({
              appName: data.data.getAppMetadata.content.title,
              adamId: app.adamId,
              appIcon: data.data.getAppMetadata.content.icon,
            });
            if (
              data.data.getAppMetadata == null ||
              data.data.getAppMetadata == undefined
            ) {
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    closeSuccessDialog() {
      this.successDialog = false;
      LSS.clearOther();
      this.$router.push('/ads-manager');
    },

    async checkCampaignName() {
      const result = await this.$apollo.query({
        query: CHECK_CAMPAIGN_NAME,
        variables: {
          name: this.campaignName,
        },
      });

      if (result.data.checkCampaignName.doesCampaignExist) {
        this.campaignNameRules.push('Campaign name already exists!');
      } else {
        this.campaignNameRules = this.campaignNameRules.filter(
          (vals) => typeof vals !== 'string'
        );
      }

      // making validation rules run manually
      const val = this.campaignName;
      this.campaignName = '';
      setTimeout(() => {
        this.campaignName = val;
      }, 0);
    },

    stepChanged() {
      this.syncDataWithLs();
    },

    async patchCampaignFormWithDraft(campaignDraft: ICampaignReq): void {
      this.orgId = campaignDraft.campaign.orgId;
      this.selectedApp = { adamId: campaignDraft.campaign.adamId };
      this.selectedCountries = campaignDraft.campaign.countriesOrRegions;
      this.budget = campaignDraft.campaign.budgetAmount.amount;
      this.currency = campaignDraft.campaign.budgetAmount.currency;
      this.dailyCap = campaignDraft.campaign.dailyBudgetAmount?.amount || '';
      this.campaignName = campaignDraft.campaign.name;
      if (campaignDraft.adGroup) {
        this.adGroupName = campaignDraft.adGroup?.name;
        this.defaultBidAmount = campaignDraft.adGroup?.defaultBidAmount?.amount;
        this.cpaGoal = campaignDraft.adGroup?.cpaGoal?.amount || '';
        this.startdate = new Date(campaignDraft.adGroup?.startTime);
        if (campaignDraft.adGroup?.endTime) {
          this.endTimeCheck = true;
          this.enddate = new Date(campaignDraft.adGroup?.endTime);
        }
        this.selectDevices =
          campaignDraft.adGroup?.targetingDimensions?.deviceClass?.included;
      }
      this.autoOpt = campaignDraft.adGroup?.automatedKeywordsOptIn;

      if (campaignDraft.targetKeywords)
        this.tKeys = campaignDraft.targetKeywords;
      if (campaignDraft.negativeKeywords)
        this.nKeys = campaignDraft.negativeKeywords;

      if (campaignDraft.adGroup?.targetingDimensions.age) {
        this.ageRange = true;
        this.ageRangeValue = [
          campaignDraft.adGroup?.targetingDimensions.age.included[0].minAge,
          campaignDraft.adGroup?.targetingDimensions.age.included[0].maxAge,
        ];
      }

      if (campaignDraft.adGroup?.targetingDimensions.gender) {
        this.selectedGender =
          campaignDraft.adGroup?.targetingDimensions.gender.included[0];
      }

      if (campaignDraft.creativeSet) {
        this.addedCreativeSetDetails = campaignDraft.creativeSet;
      }

      await this.moveToStep(campaignDraft.step);
    },

    async moveToStep(step: number): void {
      this.step = step;
      this.setProperties(true);
      await this.saveCountriesRegions(true);

      if (step < CampaignCreationSteps.SEARCH_MATCH) return;

      this.saveAdGroupObject(true);

      if (step < CampaignCreationSteps.KEYWORDS) return;

      this.saveAutoOptIn(true);

      if (step < CampaignCreationSteps.AUDIENCE) return;

      this.saveKeywords(true);

      if (step < CampaignCreationSteps.CREATIVE_SETS) return;

      this.appendAudience(true);
    },

    syncDataWithLs() {
      // watching 'step' property to get 'previousStep' in watchers
      switch (this.previousStep) {
        case CampaignCreationSteps.APP_SELECTION: {
          this.setProperties(true);
          break;
        }
        case CampaignCreationSteps.COUNTRIES_REGIONS: {
          this.saveCountriesRegions(true);
          break;
        }
        case CampaignCreationSteps.AD_GROUP: {
          this.saveAdGroupObject(true);
          break;
        }
        case CampaignCreationSteps.SEARCH_MATCH: {
          this.saveAutoOptIn(true);
          break;
        }
        case CampaignCreationSteps.KEYWORDS: {
          this.saveKeywords(true);
          break;
        }
        case CampaignCreationSteps.AUDIENCE: {
          this.appendAudience(true);
          break;
        }
      }
    },

    async createCampaign() {
      this.savingCampaign = true;
      const campaignRequest: ICampaignReq = {
        campaign: this.campaign,
        adGroup: this.adGroup,
        creativeSet: this.addedCreativeSetDetails,
        targetKeywords: this.tKeys,
        negativeKeywords: this.nKeys,
        draftId: this.draftId,
      };
      try {
        const result = await this.runMutation({
          mutationQuery: CREATE_CAMPAIGN,
          variables: {
            orgId: this.orgId,
            campaignRequest,
          },
        });

        this.savingCampaign = false;

        if (result.data.createCampaign.searchAdsErrors) {
          return this.$notify.error(result.data.createCampaign.searchAdsErrors);
        }
        updateCLF('createCampaign', this);
        this.successDialog = true;
      } catch (error) {
        this.savingCampaign = false;
        console.log(error);
      }
    },
    async saveAsDraft() {
      this.savingCampaignDraft = true;
      const campaignDraft: ICampaignReq = {
        campaign: this.campaign,
        adGroup: this.adGroup,
        targetKeywords: this.tKeys,
        negativeKeywords: this.nKeys,
        creativeSet: this.addedCreativeSetDetails,
        step: this.step,
      };

      try {
        await this.runMutation({
          mutationQuery: CAMPAIGN_DRAFT,
          variables: { campaignDraft, draftId: this.draftId },
        });

        this.$notify.success('Campaign saved as a draft.');
        this.savingCampaignDraft = false;
        this.$router.push('ads-manager');
      } catch {
        this.$notify.error(
          'Unable to save campaign draft at the moment. Please try again!'
        );
        this.savingCampaignDraft = false;
      }
    },
    async searchLocations(locationName: string, countrycode: string) {
      this.loading = true;
      this.locations = await CampaignsService.searchedLocations(
        this.orgId,
        locationName,
        countrycode
      );
      this.loading = false;
    },
    setProperties(syncing = false) {
      if (!syncing) this.step = CampaignCreationSteps.COUNTRIES_REGIONS;
    },
    async saveCountriesRegions(syncing = false) {
      this.savingCountriesRegions = true;
      const campaign: ICampaign = {
        countriesOrRegions: this.selectedCountries,
        name: this.campaignName,
        budgetAmount: {
          currency: this.currency,
          amount: this.budget,
        },
        ...(this.dailyCap && {
          dailyBudgetAmount: {
            currency: this.currency,
            amount: this.dailyCap,
          },
        }),
        orgId: parseInt(this.orgId),
        adChannelType: 'SEARCH',
        supplySources: ['APPSTORE_SEARCH_RESULTS'],
        adamId: parseInt(this.selectedApp.adamId),
        // TODO v4
        billingEvent: 'TAPS',
      };

      this.campaign = campaign;
      try {
        if (this.selectedApp?.adamId && this.selectedCountries[0]) {
          const creativeappassets = await CampaignsService.creativeappassets(
            this.orgId,
            this.selectedApp.adamId,
            this.selectedCountries
          );
          this.csName = 'cs-' + Date.now();
          const enUs = creativeappassets.creativeSetDetails['en-US'];
          this.assignImages(enUs.appPreviewDeviceWithAssets);
        }
      } catch {
        this.noCreativeSet = true;
      }
      if (!syncing) this.step = CampaignCreationSteps.AD_GROUP;
      this.savingCountriesRegions = false;
    },
    assignImages(object) {
      this.allImgs['tabs'] = []; //add tabs property to allImgs object, this will contain tab names
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          this.allImgs['tabs'].push(key); // for each key, push it into allImgs['tabs']
          this.allImgs[key] = []; //add key itself as a property
          const imgs = object[key].screenshots; //get screenshot details for each key
          imgs.forEach((element) => {
            const temp = {
              //create object with url and genId and push it into allImgs
              assetURL: element.assetURL,
              assetGenId: element.assetGenId,
            };
            this.allImgs[key].push(temp);
          });
          this.defaultImages.push(imgs[0].assetURL);
          this.defaultGenIds.push(imgs[0].assetGenId);
        }
      }
    },
    openAddCreativeSet() {
      this.appDetailsForCreativeSet = {
        orgId: this.orgId,
        adamId: this.selectedApp.adamId,
        countries: this.selectedCountries,
        now: Date.now(),
      };
    },
    async saveAdGroupObject(syncing = false) {
      this.campaign = {
        ...this.campaign,
        startTime: this.startdate,
        ...(this.enddate && {
          endTime: this.enddate,
        }),
      };

      const adGroup: IAdGroup = {
        orgId: parseInt(this.orgId),
        status: 'ENABLED',
        name: this.adGroupName,
        // TODO v4
        pricingModel: 'CPC',
        defaultBidAmount: {
          currency: this.currency,
          amount: this.defaultBidAmount,
        },
        ...(this.cpaGoal && {
          cpaGoal: {
            currency: this.currency,
            amount: this.cpaGoal,
          },
        }),
        startTime: this.startdate,
        ...(this.enddate && { endTime: this.enddate }),
        targetingDimensions: {
          deviceClass: {
            included: this.selectDevices,
          },
          country: {
            included: [],
          },
          ...(this.daypart && {
            daypart: {
              userTime: {
                included: this.daypart.split(','),
              },
            },
          }),
        },
      };
      this.adGroup = adGroup;
      if (!syncing) this.step = CampaignCreationSteps.SEARCH_MATCH;
    },
    async saveAutoOptIn(syncing = false) {
      this.adGroup = {
        ...this.adGroup,
        automatedKeywordsOptIn: this.autoOpt,
      };
      if (!syncing) this.step = CampaignCreationSteps.KEYWORDS;
    },
    tKeysChange: async function (e) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          text: elem.name,
          matchType: elem.match,
          bidAmount: {
            currency: elem.currency,
            amount: elem.amount.toString(),
          },
        }));
        //console.log(result);
        result.forEach((element) => {
          this.tKeys.push(element);
        });
        //this.tKeys = result;
      };
      reader.readAsArrayBuffer(e);
    },
    nKeysChange: function (e) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          text: elem.name,
          matchType: elem.match,
        }));
        result.forEach((element) => {
          this.nKeys.push(element);
        });
        //this.nKeys = result;
      };
      reader.readAsArrayBuffer(e);
    },
    async saveKeywords(syncing = false) {
      if (!syncing) this.step = CampaignCreationSteps.AUDIENCE;
    },
    async appendAudience(syncing = false) {
      const adamId = this.selectedApp.adamId;
      const allOwnedApps = this.appList.map((app) => app.adamId);
      const audience: IAudience = {
        // getting audience object set by adgroup screen
        ...this.adGroup.targetingDimensions,
        // age criteria
        ...(this.ageRange && {
          age: {
            included: [
              { minAge: this.ageRangeValue[0], maxAge: this.ageRangeValue[1] },
            ],
          },
        }),
        // gender criteria
        ...(this.selectedGender && {
          gender: {
            included: [this.selectedGender],
          },
        }),
        // App downloader criteria (customer type drop-down)
        ...(this.selectedCustomerType === CustomerTypes.NEW && {
          appDownloaders: {
            excluded: [adamId.toString()],
          },
        }),
        ...(this.selectedCustomerType === CustomerTypes.EXISITING && {
          appDownloaders: {
            included: [adamId.toString()],
          },
        }),
        ...(this.selectedCustomerType === CustomerTypes.OTHER_APPS && {
          appDownloaders: {
            included: [...allOwnedApps.map((adamId) => adamId.toString())],
          },
        }),
        ...(this.selectedLocation && {
          locality: { included: this.selectedLocation },
        }),
      };
      this.adGroup = {
        // TODO v4
        pricingModel: 'CPC',
        ...(this.adGroup.defaultBidAmount && {
          defaultBidAmount: this.adGroup.defaultBidAmount,
        }),
        ...(this.adGroup.cpaGoal && { cpaGoal: this.adGroup.cpaGoal }),
        ...(this.adGroup.startTime && { startTime: this.adGroup.startTime }),
        ...(this.adGroup.endTime && { endTime: this.adGroup.endTime }),
        ...(this.adGroup.status && { status: this.adGroup.status }),
        ...(this.adGroup.orgId && { orgId: this.adGroup.orgId }),
        ...(this.adGroup.automatedKeywordsOptIn && {
          automatedKeywordsOptIn: this.adGroup.automatedKeywordsOptIn,
        }),
        ...(this.adGroup.name && { name: this.adGroup.name }),
        targetingDimensions: audience,
      };

      if (!syncing) this.step = CampaignCreationSteps.CREATIVE_SETS;
    },
    creativeSetObject({ creativeSet }) {
      this.addedCreativeSetDetails = creativeSet;
    },
    async runMutation(mutationOptions: IMutationOptions) {
      return await this.$apollo.mutate({
        mutation: mutationOptions.mutationQuery,
        variables: mutationOptions.variables,
      });
    },
    addTargetKeywords() {
      this.addKeywordsData = {
        appId: this.selectedApp.adamId,
        appName: this.selectedApp.appName,
        currency: this.currency,
        countryCode: this.selectedCountries[0],
        now: Date.now(),
      };
    },
    addNegativeKeywords() {
      this.addnKeywordsData = {
        now: Date.now(),
      };
    },
    addTargetKeysCancelClicked() {
      console.log('addKeysCancelClicked');
    },
    addTargetKeysSaveClicked(event) {
      console.log('addKeysSaveClicked', event);
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.tKeys.push(element);
      }
    },
    clearTKeys() {
      this.tKeys = [];
      this.targetingKeywordsFile = null;
    },
    addnKeysSaveClicked(event) {
      console.log('addKeysSaveClicked', event);
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.nKeys.push(element);
      }
    },
    clearNKeys() {
      this.nKeys = [];
      this.negativeKeywordsFile = null;
    },
    addnKeysCancelClicked() {
      console.log('addnKeysCancelClicked');
    },
    deleteNkeyFromTable(item) {
      //console.log('deleteNkeyFromTable',item);
      this.nKeys = this.nKeys.filter((kw) => kw !== item);
    },
    deleteTkeyFromTable(item) {
      //console.log('deleteTkeyFromTable',item);
      this.tKeys = this.tKeys.filter((kw) => kw !== item);
    },

    setCountries(data) {
      const Countries = data[0].countryOrRegionCodes;
      for (const countryCode of Countries) {
        for (const country of this.Countries) {
          if (country.Code == countryCode) {
            this.appCountries.push(country);
          }
        }
      }
    },
  },
  watch: {
    selectedCountries(value) {
      value && value.length > 1
        ? (this.areMultipleCountries = true)
        : (this.areMultipleCountries = false);
    },
    orgId(value) {
      // handling currency as per the org
      if (value)
        this.currency = this.getCampaignGroups.find(
          (group) => group.orgId === value
        ).currency;
    },
    searchLoc(query: string): void {
      query &&
        query !== this.selectedLocation &&
        this.selectedCountries.length === 1 &&
        query.length > 2 &&
        this.searchLocations(query, this.selectedCountries[0]);
    },

    step(current: number, previous: number): void {
      this.previousStep = Number(previous);
    },
  },
  async created() {
    this.Countries = COUNTRIES;

    if (this.draftId) {
      this.$apollo.queries.campaignDraft.skip = false;
      this.fullscreenLoader = this.$loading.show();
    }

    window.addEventListener('beforeunload', () => {
      LSS.clearOther();
    });
  },
});
